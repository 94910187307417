import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Colour, EspritProduct, ProductMisc, VariationAttribute, VariationGroup , VariationAttributeValue} from '../Types';
import { oneSize, ProductMiscList, ColourList, Currencys } from '../Constants';
import { fetchJson } from '../Api';

const apiLink = '/api/products/';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 12px;
  color: #000000;
  font-family: 'Suisse Intl';
  background-color: transparent;
  min-height: 680px;
`;

const SideCol = styled.div`
  width: 40%;
`;

const Detail = styled.div`
  background-color: #ffffff;
  width: 375px;
  min-height: 680px;
  align-content: center;
`;

const CarouselContainer = styled(Carousel as any)<any>`
  max-width: 375px;
  .carousel .control-next.control-arrow,
  .carousel .control-next.control-arrow:hover {
    background-color: inherit;
  }

  .carousel .control-prev.control-arrow,
  .carousel .control-prev.control-arrow:hover {
    background-color: inherit;
  }

  .carousel carousel-slider {
    display: flex;
  }

  .carousel .control-dots {
    background-color: #d8d8d8;
    border-radius: 12px;
    padding: 2px 1px;
    width: 70px;
    height: 15px;
    left: calc(50% - 70px / 2);
    display: flex;
    justify-content: space-evenly;
    opacity: 0.5;

    .dot {
      position: static;
      background: #121212;
      opacity: 1;
      width: 3px;
      height: 3px;
      margin: 4px;
    }

    .dot.selected {
      width: 6px;
      height: 6px;
      margin: 2px;
      background: #121212;
    }
  }

  .carousel .control-next.control-arrow:before {
    content: '';
    border: solid #121212;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #121212;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
`;

const CarouselImage = styled.img`
  width: 375px;
  height: 322px;
  object-fit: cover;
`;

const Inner = styled.div`
  padding: 10px 0px 0px 15px;
  height: 225px;
  width: 360px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Name = styled.div`
  min-height: 24px;
  width: 360px;
  color: #121212;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin-bottom: 5px;
`;

const Price = styled.div`
  height: 21px;
  width: 87px;
  color: #121212;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 14px;
`;

const Selector = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 15px;
`;

const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
`;

const FlexRowDivBetween = styled(FlexRowDiv)`
  justify-content: space-between;
`;

const Label = styled.span`
  color: #121212;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
`;

const Selected = styled.div`
  color: #121212;
  font-weight: 500;
  font-size: 12px;
`;

const FixWidth = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;

const Border = styled.div<any>`
  height: ${(props) => (props.isActive ? '24px' : '')};
  width: ${(props) => (props.isActive ? '24px' : '')};
  border: ${(props) => (props.isActive ? '1px solid #121212' : '')};
  border-radius: 22px;
`;

const ColourBox = styled.div<any>`
  height: ${(props) => (props.isActive ? '14px' : '24px')};
  width: ${(props) => (props.isActive ? '14px' : '24px')};
  margin: 0px 10px 15px 0px;
  border-radius: 22px;
  cursor: pointer;
  background-color: ${(props) => props.color};
  border: ${(props) => (props.isActive ? '5px solid #FFFFFF' : '')};
`;

const Sizebox = styled.div<any>`
  border: 1px solid #444444;
  size: 9.6px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  width: 50px;
  height: 15px;
  text-align: center;
  align-content: center;
  padding: 5px 5px;
  margin: 0px 8px 7px 0px;
  background-color: ${(props) => (props.isActive ? '#000000' : '#ffffff')};
  color: ${(props) => (props.isActive ? '#ffffff' : '#000000')};
`;

const OneSizebox = styled(Sizebox)`
  size: 8px;
  white-space: nowrap;
`;

const Info = styled.div`
  font-family: 'Suisse Intl';
  font-size: 12px;
  font-weight: 300;
  width: 350px;
  > ul {
    list-style-position: inside;
    padding-left: 0;
    text-indent: -15px;
    margin-left: 15px;
  }
`;

const MoreInfo = styled.div`
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
`;

const Fix = styled.div`
  background-color: #ffffff;
  position: relative;
  left: 5;
  bottom: -10;
  right: 5;
`;

const BreakLine = styled.hr`
  border: 0;
  border-top: 1px solid #e3e3e3;
  width: 100%;
`;

const AddButton = styled.div<any>`
  background-color: ${(props) => (props.isAdded ? '#d2d2d2' : '#000000')};
  border-radius: 22.5px;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => (props.isAdded ? '#f2f2f2' : '#ffffff')};
  height: 45px;
  width: 300px;
  text-align: center;
  line-height: 46px;
  margin: 25px 37.5px;
  cursor: ${(props) => (props.isAdded ? 'not-allowed' : 'pointer')};

  :hover {
    background-color: ${(props) => (props.isAdded ? '#d2d2d2' : '#4a4a4a')};
  }
`;

type colourSelectorProps = {
  colours: Colour[];
  preselectedColour?: Colour;
  selectedColour: React.Dispatch<React.SetStateAction<Colour>>;
};

const ColourSelector = ({ colours, preselectedColour, selectedColour }: colourSelectorProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Colour>(preselectedColour ?? colours[0]);
  useEffect(() => {
    selectedColour(selected);
  }, [selected, selectedColour]);

  useEffect(() => {
    setSelected(preselectedColour ?? colours[0]);
  }, [colours, preselectedColour]);

  return (
    <>
      <FlexRowDiv>
        <Label>{t('Colour')}</Label>&nbsp;
        <Selected>{selected?.name && selected?.name.toUpperCase()}</Selected>
      </FlexRowDiv>
      <Selector>
        {colours.map((colour) => {
          return (
            <FixWidth key={colour?.name}>
              <Border key={colour?.name} color={colour?.hex} isActive={colour?.code === selected?.code}>
                <ColourBox
                  key={colour?.name}
                  color={colour?.hex}
                  isActive={colour?.code === selected?.code}
                  onClick={() => setSelected(colour)}
                />
              </Border>
            </FixWidth>
          );
        })}
      </Selector>
    </>
  );
};

type sizeSelectorProps = {
  sizes: string[];
  preselectedSize?: string;
  selectedSize: React.Dispatch<React.SetStateAction<string>>;
  isOneSize: boolean;
};

const SizeSelector = ({ sizes, preselectedSize, selectedSize, isOneSize }: sizeSelectorProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(preselectedSize ?? sizes[2]);
  useEffect(() => {
    selectedSize(selected);
  }, [selected, selectedSize]);

  useEffect(() => {
    setSelected(preselectedSize ?? sizes[2]);
  }, [sizes, preselectedSize]);

  return (
    <>
      <FlexRowDiv>
        <Label>{t('Size')}</Label>&nbsp;
        <Selected>{!isOneSize && selected}</Selected>
      </FlexRowDiv>
      <Selector>
        {!isOneSize ? (
          sizes.map((size) => {
            return (
              <Sizebox key={size} isActive={size === selected} onClick={() => setSelected(size)}>
                {size.toUpperCase()}
              </Sizebox>
            );
          })
        ) : (
          <OneSizebox key={oneSize[0]} isActive={true} onClick={() => setSelected(oneSize[0])}>
            {oneSize[0]}
          </OneSizebox>
        )}
      </Selector>
    </>
  );
};

const Product = () => {
  const { t } = useTranslation();
  const currency = Currencys['HKD'];
  let { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<EspritProduct | null>(null);
  const [productMisc, setProductMisc] = useState<ProductMisc>({} as ProductMisc);

  const [colours, setColours] = useState<Colour[]>();
  const [selectedColour, setSelectedColour] = useState<Colour>({} as Colour);
  const [sizes, setSizes] = useState<string[]>([]);
  const [selectedSize, setSelectedSize] = useState('M');
  const [showInfo, setShowInfo] = useState(false);
  // const [items, setItems] = useState<Array<Item>>([]);
  // const [isAdded, setIsAdded] = useState<boolean>(false);

  const displayImages = productMisc?.images ?? [];

  const SetProductMisc = (selectedColour: Colour) => {
    let productCode = productId?.substring(0, productId?.lastIndexOf('_')) ?? '';
    let productMisc = ProductMiscList.filter((productMisc) => {
      return productMisc.productId === productCode + '_' + selectedColour.code;
    })[0];
    setProductMisc(productMisc);
  };

  useEffect(() => {
    fetchJson<EspritProduct>(apiLink + productId + '/' + currency.code).then(setProduct);
  }, [currency.code, productId]);

  useEffect(() => {
    if (product) {
      //filter color by variation groups
      let productOrderableList: VariationGroup[]= product.variation_groups.filter((variation) => {
        return variation.orderable === true;
      });

      let productColorsAttribute: VariationAttribute = product.variation_attributes.filter((variation) => {
        return variation.id === 'color';
      })[0];

      setColours(() => {
        return productOrderableList.map((availableItem) => {
          let colorCode  = availableItem.variation_values['color'];
          let productAttributeValue: VariationAttributeValue = productColorsAttribute.values.filter((attribute) => attribute.value === colorCode )[0];
          let availableColor: Colour = ColourList.filter((colour) => { 
            return colorCode === colour.code && productAttributeValue.name.toUpperCase() === colour.name.toUpperCase();
          })[0];
          return availableColor;
        });
      });

      let colourCode = productId?.substring(productId?.lastIndexOf('_') + 1, productId.length) ?? '';
      let existingColour = ColourList.filter((colour) => {
        return colourCode === colour.code;
      })[0];
      setSelectedColour(existingColour);

      SetProductMisc(existingColour);

      let productSizes: VariationAttribute = product.variation_attributes.filter((variation) => {
        return variation.id === 'size';
      })[0];
      let sizes = productSizes.values.map((size) => {
        return size.name;
      });
      setSizes(sizes);
      setSelectedSize(sizes[2]);
    }
  }, [product]);

  useEffect(() => {
    SetProductMisc(selectedColour);
  }, [SetProductMisc, selectedColour]);

  // useEffect(() => {
  //   const existingItems = JSON.parse(localStorage.getItem('items') ?? '[]');
  //   setItems(existingItems);
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem('items', JSON.stringify(items));
  // }, [items]);

  return (
    <>
      <Container>
        <SideCol />
        {product && (
          <Detail>
            <CarouselContainer showThumbs={false} showStatus={false} infiniteLoop={false}>
              {displayImages.map((imageLink, index) => {
                return (
                  <div>
                    <CarouselImage alt='image' src={imageLink} />
                  </div>
                );
              })}
            </CarouselContainer>
            <Inner>
              <Name>{productMisc?.name ?? product?.name}</Name>
              <Price>{`${currency.label}${product.price}.00`}</Price>
              <ColourSelector colours={colours ?? []} preselectedColour={selectedColour} selectedColour={setSelectedColour} />
              <SizeSelector
                sizes={sizes ?? []}
                preselectedSize={selectedSize}
                selectedSize={setSelectedSize}
                isOneSize={productMisc?.isOneSize ?? false}
              />
              <FlexRowDivBetween>
                <MoreInfo onClick={() => setShowInfo(!showInfo)}>{t('More Info')}</MoreInfo>
              </FlexRowDivBetween>
              {showInfo && (
                <Info>
                  <p>{product?.long_description}</p>
                  <ul>
                    {product?.c_bulletpoints.map((bulletPoints) => {
                      return <li>{bulletPoints}</li>;
                    })}
                  </ul>
                </Info>
              )}
            </Inner>
            <Fix>
              <BreakLine />
              <AddButton
                // isAdded={isAdded}
                onClick={() => {
                  // setItems([
                  //   ...items,
                  //   { name: name, productId: pid, size: selectedSize, colour: selectedColour, price: price, quantity: 1 },
                  // ]);
                  // setIsAdded(true);
                  window.open('https://www.esprit.hk/en/p/' + productMisc?.link, '_blank');
                }}
              >
                {t('Learn More')}
              </AddButton>
            </Fix>
          </Detail>
        )}
        <SideCol />
      </Container>
    </>
  );
};

export default Product;
