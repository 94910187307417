import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ArrowIcon from '../images/guide_arrow_icon.png';
import DiscoverIcon from '../images/guide_discover_icon.png';
import InfoIcon from '../images/guide_info_icon.png';
import LandingBackground from '../images/landing_background.png';
import Gradient from '../images/gradient.png';
import AppHeader from './Header';

const FrontpageContainer = styled.div<any>`
  height: calc(100vh - 2 * 40px);
  width: calc(100vw - 2 * 40px);
  padding: 40px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    /* height: calc(100vh - 2 * 20px);
    width: calc(100vw - 2 * 20px); */
    height: -webkit-fill-available;
    height: -moz-available;
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 20px;
  }
  /*
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  //scrollbar-width: none; /*Firefox*/
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: 30px;
  font-weight: 600;
  @media (max-width: 768px) {
    width: auto;
    height: auto;
    text-align: center;
  }
`;

const Intro = styled.span`
  font-size: 18px;
  font-weight: 400;
  /* width: 697px; */
  /* height: 84px; */
  text-align: center;
  @media (max-width: 768px) {
    width: auto;
    height: auto;
  }
`;

const FeatureGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 1077px;
  margin: 50px 0px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: 10px 0px 140px 0px;
  }
`;

const FeatureContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 55px 5px 5px 0px;

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

const FeatureHeader = styled.h1`
  font-size: 18px;
  font-weight: 600;
  margin: 5px 5px 5px 0px;

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

const FeatureDesc = styled.span`
  text-align: center;
  margin: 5px 5px 5px 0px;

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

const FeatureImg = styled.img`
  background-color: transparent;
  width: 52.5px;
  height: 52.5px;
  margin: 5px 0px 5px 0px;
`;

const ExploreButton = styled.button`
  border-radius: 24px;
  height: 48px;
  width: 200px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

const Scroll = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    height: calc(100vh - 150px);
  }
`;

const Fix = styled.div<any>`
  @media (max-width: 768px) {
    display: flex;
    width: 100vw;
    vertical-align: center;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    position: absolute;
    /* left: 5px; */
    bottom: 0;
    /* right: 5px; */
  }
`;

interface FeatureProps {
  caption?: string;
  desc: string;
  img?: string;
}

const Feature = ({ caption, desc, img }: FeatureProps) => {
  return (
    <FeatureContainer>
      <FeatureHeader>{caption}</FeatureHeader>
      <FeatureDesc>{desc}</FeatureDesc>
      {img ? <FeatureImg src={img} alt={'Esprit'} /> : <span style={{ height: '110px' }}></span>}
    </FeatureContainer>
  );
};

const Content = () => {
  const { t } = useTranslation();
  return (
    <FrontpageContainer image={LandingBackground}>
      <AppHeader />
      <Container>
        <Scroll>
          <Header>{t('Welcome Message')}</Header>
          <Intro>{t('Welcome Description')}</Intro>
          <FeatureGroup>
            <Feature caption={t('Navigation')} desc={t('Navigation Description')} img={ArrowIcon ?? ''} />
            <Feature caption={t('Product')} desc={t('Product Description')} img={DiscoverIcon ?? ''} />
            <Feature caption={t('Discover')} desc={t('Discover Description')} img={InfoIcon ?? ''} />
          </FeatureGroup>
        </Scroll>
        <Fix image={Gradient}>
          <ExploreButton onClick={() => (window.location.href = 'V2/index.htm')}>{t('Explore Now')}</ExploreButton>
        </Fix>
      </Container>
    </FrontpageContainer>
  );
};

export default Content;
