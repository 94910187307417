import { useState } from 'react';
import styled from 'styled-components';
import LanguageIcon from '../images/language.png';
import { Languages } from '../Constants';
import Card from './Card';
import { useTranslation } from 'react-i18next';
import { Language } from '../Types';

const locales = Languages;

const Langauge = styled.img`
  background-color: transparent;
  width: 32px;
  height: 32px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 0;
  height: 36px;
  width: 36px;
  border-radius: 0%;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  @media (max-width: 768px) {
    height: 20px;
    width: 20px;
    justify-content: flex-start;
  }
`;

const Dropdown = styled(Card)`
  box-sizing: border-box;
  position: absolute;
  top: 30px;
  right: -80px;
  margin: 5px;
  padding: 8px;
  transition: all 0s ease 100ms;
  z-index: 0;

  @media (max-width: 768px) {
    top: 20px;
    right: -75px;
  }
`;

const DropdownHover = styled(Dropdown)`
  visibility: hidden;
  ${Item}:hover & {
    transition-delay: 0s;
    visibility: visible;
  }
`;

const DropdownMenu = styled(DropdownHover)`
  > div {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 42px;
    width: 100px;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Suisse Intl';
    white-space: nowrap;
    text-decoration: none;

    @media (max-width: 768px) {
      height: 30px;
      width: 80px;
      font-size: 16px;
    }
  }

  :hover {
    cursor: pointer;
  }

  > div::after {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  ${Item}:hover & > *:hover {
    color: ${(props) => '#000000'};
    background-color: ${(props) => '#FFFFFF'};
  }
`;

const LocaleSelector = () => {
  const { i18n } = useTranslation();
  const changeLanguageHandler = (lang: Language) => {
    i18n.changeLanguage(lang.code);
  };

  return (
    <Item>
      <Langauge src={LanguageIcon} alt='change language' />
      <DropdownMenu>
        {locales.map((locale) => {
          return (
            <div key={locale.code} onClick={() => changeLanguageHandler(locale)}>
              {locale.display}
            </div>
          );
        })}
      </DropdownMenu>
    </Item>
  );
};

export default LocaleSelector;
