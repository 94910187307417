import { Colour, ProductMisc, Language, Currency, CurrencyObjList } from './Types';

const sizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];

const oneSize = ['one size'];

const Languages: Language[] = [
  { code: 'en', display: 'English' },
  // { code: 'zh', display: '繁體' },
];

const shareLink = 'http://' + window.location.hostname;

const fileLocation = '/images';

const Currencys: CurrencyObjList = {
  HKD: { code: 'HKD', label: 'HK$', format: '' },
  USD: { code: 'USD', label: 'USD', format: '' },
};

const black: Colour = { name: 'black', hex: '#171516', code: '001' };
const black2: Colour = { name: 'black', hex: '#171516', code: '002' };
const grey: Colour = { name: 'grey', hex: '#cfced5', code: '034' };
const lightgrey: Colour = { name: 'light grey', hex: '#cfced5', code: '044' };
const pastelYellow: Colour = { name: 'pastel yellow', hex: '#f8f1ae', code: '050' };
const white: Colour = { name: 'white', hex: '#f8f8f0', code: '100' };
const khaki: Colour = { name: 'khaki beige', hex: '#d5b48a', code: '250' };
const beige: Colour = { name: 'beige', hex: '#f5f5dc', code: '270' };
const sand: Colour = { name: 'sand', hex: '#c2b280', code: '285' };
const lightBeige: Colour = { name: 'light beige', hex: '#f5f5dc', code: '290' };
const darkGreen: Colour = { name: 'dark green', hex: '#304346', code: '300' };
const green: Colour = { name: 'green', hex: '#4ca57b', code: '310' };
const dustyGreen: Colour = { name: 'dusty green', hex: '#f0eeb5', code: '335' };
const lightAquaGreen: Colour = { name: 'light aqua green', hex: '#17a2b8', code: '390' };
const navy: Colour = { name: 'navy', hex: '#202A44', code: '400' };
const darkblue405: Colour = { name: 'dark blue', hex: '#0f1627', code: '405' };
const blue: Colour = { name: 'blue', hex: '#408fe1', code: '430' };
const lightblue: Colour = { name: 'light blue', hex: '#66a4e7', code: '440' };
const petrolBlue: Colour = { name: 'petrol blue', hex: '#125196', code: '450' };
const tealBlue: Colour = { name: 'teal blue', hex: '#0b2e53', code: '455' };
const darkBlue: Colour = { name: 'dark blue', hex: '#202A44', code: '470' };
const purple: Colour = { name: 'purple', hex: '#785ea0', code: '510' };
const lilac: Colour = { name: 'lilac', hex: '#6610f2', code: '560' };
const lavender: Colour = { name: 'lavender', hex: '#c0b2d5', code: '570' };
const red: Colour = { name: 'red', hex: '#e84743', code: '630' };
const pinkFuchsia: Colour = { name: 'pink fuchsia', hex: '#e83e8c', code: '660' };
const pink: Colour = { name: 'pink', hex: '#ff69b4', code: '670' };
const yellow: Colour = { name: 'yellow', hex: '#ffff00', code: '750' };
const brightOrange: Colour = { name: 'bright orange', hex: '#ff6c33', code: '750' }; //duplicated code
const orange: Colour = { name: 'orange', hex: '#e27550', code: '820' };
const blueRinse: Colour = { name: 'blue rinse', hex: '#b7bdc6', code: '900' };

const ColourList: Colour[] = [
  black,
  black2,
  white,
  lightAquaGreen,
  lilac,
  lavender,
  lightBeige,
  pink,
  pinkFuchsia,
  green,
  dustyGreen,
  grey,
  lightgrey,
  khaki,
  beige,
  sand,
  navy,
  darkblue405,
  blue,
  petrolBlue,
  tealBlue,
  darkBlue,
  lightblue,
  blueRinse,
  red,
  purple,
  orange,
  brightOrange,
  darkGreen,
  yellow,
  pastelYellow,
];

// const ReIssueColourTeeColourList: Colour[] = [black, white, cyan, lilac, pink];
// const ReIssueColourSweatColourList: Colour[] = [grey, navy, red, yellow];
// const LoveCompositeTeeColourList: Colour[] = [green, black, grey, white, khaki, blue, red, purple, orange];
// const LoveCompositeHoodieColourList: Colour[] = [black, grey, white, khaki, blue, red, green, purple, orange];
// const ColourTeeColourList: Colour[] = [khaki, darkGreen, blue, red];
// const ToteBagColourList: Colour[] = [beige];

const reIssueColourTeePrice = 599;
const reIssueColourSweatPrice = 999;
const loveCompositeTeePrice = 499;
const loveCompositeHoodiePrice = 1199;
const colorCapsuleTeePrice = 499;
const colorCapsuleToteBagPrice = 499;

const ReIssueColourTeeList: ProductMisc[] = [
  {
    productId: '111EE2K304_001',
    price: reIssueColourTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K304_001.html',
    images: [
      fileLocation + '/archive_black_1.jpg',
      fileLocation + '/archive_black_2.jpg',
      fileLocation + '/archive_black_3.jpg',
      fileLocation + '/archive_black_4.jpg',
      fileLocation + '/archive_black_5.jpg',
    ],
  },
  {
    productId: '111EE2K304_100',
    price: reIssueColourTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K304_100.html',
    images: [
      fileLocation + '/archive_white_1.jpg',
      fileLocation + '/archive_white_2.jpg',
      fileLocation + '/archive_white_3.jpg',
      fileLocation + '/archive_white_4.jpg',
      fileLocation + '/archive_white_5.jpg',
    ],
  },
  {
    productId: '111EE2K304_390',
    price: reIssueColourTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K304_390.html',
    images: [
      fileLocation + '/archive_blue_1.jpg',
      fileLocation + '/archive_blue_2.jpg',
      fileLocation + '/archive_blue_3.jpg',
      fileLocation + '/archive_blue_4.jpg',
      fileLocation + '/archive_blue_5.jpg',
    ],
  },
  {
    productId: '111EE2K304_560',
    price: reIssueColourTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K304_560.html',
    images: [
      fileLocation + '/archive_purple_1.jpg',
      fileLocation + '/archive_purple_2.jpg',
      fileLocation + '/archive_purple_3.jpg',
      fileLocation + '/archive_purple_4.jpg',
      fileLocation + '/archive_purple_5.jpg',
    ],
  },
  {
    productId: '111EE2K304_660',
    price: reIssueColourTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K304_660.html',
    images: [
      fileLocation + '/archive_pink_1.jpg',
      fileLocation + '/archive_pink_2.jpg',
      fileLocation + '/archive_pink_3.jpg',
      fileLocation + '/archive_pink_4.jpg',
      fileLocation + '/archive_pink_5.jpg',
    ],
  },
];

const ReIssueColourSweatList: ProductMisc[] = [
  {
    productId: '111EE2J313_044',
    price: reIssueColourSweatPrice,
    link: 'archive-re-issue-color-sweatshirt-111EE2J313_044.html',
    images: [
      fileLocation + '/archive_sweatshirt_grey_1.jpg',
      fileLocation + '/archive_sweatshirt_grey_2.jpg',
      fileLocation + '/archive_sweatshirt_grey_3.jpg',
      fileLocation + '/archive_sweatshirt_grey_4.jpg',
      fileLocation + '/archive_sweatshirt_grey_5.jpg',
    ],
  },
  {
    productId: '111EE2J313_400',
    price: reIssueColourSweatPrice,
    link: 'archive-re-issue-color-sweatshirt-111EE2J313_400.html',
    images: [
      fileLocation + '/archive_sweatshirt_blue_1.jpg',
      fileLocation + '/archive_sweatshirt_blue_2.jpg',
      fileLocation + '/archive_sweatshirt_blue_3.jpg',
      fileLocation + '/archive_sweatshirt_blue_4.jpg',
      fileLocation + '/archive_sweatshirt_blue_5.jpg',
    ],
  },
  {
    productId: '111EE2J313_630',
    price: reIssueColourSweatPrice,
    link: 'archive-re-issue-color-sweatshirt-111EE2J313_630.html',
    images: [
      fileLocation + '/archive_sweatshirt_red_1.jpg',
      fileLocation + '/archive_sweatshirt_red_2.jpg',
      fileLocation + '/archive_sweatshirt_red_3.jpg',
      fileLocation + '/archive_sweatshirt_red_4.jpg',
      fileLocation + '/archive_sweatshirt_red_5.jpg',
    ],
  },
  {
    productId: '111EE2J313_750',
    price: reIssueColourSweatPrice,
    link: 'archive-re-issue-color-sweatshirt-111EE2J313_750.html',
    images: [
      fileLocation + '/archive_sweatshirt_yellow_1.jpg',
      fileLocation + '/archive_sweatshirt_yellow_2.jpg',
      fileLocation + '/archive_sweatshirt_yellow_3.jpg',
      fileLocation + '/archive_sweatshirt_yellow_4.jpg',
      fileLocation + '/archive_sweatshirt_yellow_5.jpg',
    ],
  },
];

const LoveCompositeTeeList: ProductMisc[] = [
  {
    productId: '111EE2K305_310',
    price: loveCompositeTeePrice,
    link: 'love-composite-capsule-t-shirt-111EE2K305_310.html',
    images: [
      fileLocation + '/love_green_1.jpg',
      fileLocation + '/love_green_2.jpg',
      fileLocation + '/love_green_3.jpg',
      fileLocation + '/love_green_4.jpg',
      fileLocation + '/love_green_5.jpg',
    ],
  },
  {
    productId: '111EE2K305_001',
    price: loveCompositeTeePrice,
    link: 'love-composite-capsule-t-shirt-111EE2K305_001.html',
    images: [
      fileLocation + '/love_black_1.jpg',
      fileLocation + '/love_black_2.jpg',
      fileLocation + '/love_black_3.jpg',
      fileLocation + '/love_black_4.jpg',
      fileLocation + '/love_black_5.jpg',
    ],
  },
  {
    productId: '111EE2K305_044',
    price: loveCompositeTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K305_044.html',
    images: [
      fileLocation + '/love_grey_1.jpg',
      fileLocation + '/love_grey_2.jpg',
      fileLocation + '/love_grey_3.jpg',
      fileLocation + '/love_grey_4.jpg',
      fileLocation + '/love_grey_5.jpg',
    ],
  },
  {
    productId: '111EE2K305_100',
    price: loveCompositeTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K305_100.html',
    images: [
      fileLocation + '/love_white_1.jpg',
      fileLocation + '/love_white_2.jpg',
      fileLocation + '/love_white_3.jpg',
      fileLocation + '/love_white_4.jpg',
      fileLocation + '/love_white_5.jpg',
    ],
  },
  {
    productId: '111EE2K305_250',
    price: loveCompositeTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K305_250.html',
    images: [
      fileLocation + '/love_khaki_1.jpg',
      fileLocation + '/love_khaki_2.jpg',
      fileLocation + '/love_khaki_3.jpg',
      fileLocation + '/love_khaki_4.jpg',
      fileLocation + '/love_khaki_5.jpg',
    ],
  },
  {
    productId: '111EE2K305_430',
    price: loveCompositeTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K305_430.html',
    images: [
      fileLocation + '/love_blue_1.jpg',
      fileLocation + '/love_blue_2.jpg',
      fileLocation + '/love_blue_3.jpg',
      fileLocation + '/love_blue_4.jpg',
      fileLocation + '/love_blue_5.jpg',
    ],
  },
  {
    productId: '111EE2K305_630',
    price: loveCompositeTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K305_630.html',
    images: [
      fileLocation + '/love_red_1.jpg',
      fileLocation + '/love_red_2.jpg',
      fileLocation + '/love_red_3.jpg',
      fileLocation + '/love_red_4.jpg',
      fileLocation + '/love_red_5.jpg',
    ],
  },
  {
    productId: '111EE2K305_510',
    price: loveCompositeTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K305_510.html',
    images: [
      fileLocation + '/love_purple_1.jpg',
      fileLocation + '/love_purple_2.jpg',
      fileLocation + '/love_purple_3.jpg',
      fileLocation + '/love_purple_4.jpg',
      fileLocation + '/love_purple_5.jpg',
    ],
  },
  {
    productId: '111EE2K305_820',
    price: loveCompositeTeePrice,
    link: 'archive-re-issue-color-t-shirt-111EE2K305_820.html',
    images: [
      fileLocation + '/love_orange_1.jpg',
      fileLocation + '/love_orange_2.jpg',
      fileLocation + '/love_orange_3.jpg',
      fileLocation + '/love_orange_4.jpg',
      fileLocation + '/love_orange_5.jpg',
    ],
  },
];

const LoveCompositeHoodieList: ProductMisc[] = [
  {
    productId: '111EE2J314_001',
    price: loveCompositeHoodiePrice,
    link: 'love-composite-capsule-hoodie-111EE2J314_001.html',
    images: [
      fileLocation + '/love_hoodies_black_1.jpg',
      fileLocation + '/love_hoodies_black_2.jpg',
      fileLocation + '/love_hoodies_black_3.jpg',
      fileLocation + '/love_hoodies_black_4.jpg',
      fileLocation + '/love_hoodies_black_5.jpg',
    ],
  },
  {
    productId: '111EE2J314_044',
    price: loveCompositeHoodiePrice,
    link: 'love-composite-capsule-hoodie-111EE2J314_044.html',
    images: [
      fileLocation + '/love_hoodies_grey_1.jpg',
      fileLocation + '/love_hoodies_grey_2.jpg',
      fileLocation + '/love_hoodies_grey_3.jpg',
      fileLocation + '/love_hoodies_grey_4.jpg',
      fileLocation + '/love_hoodies_grey_5.jpg',
    ],
  },
  {
    productId: '111EE2J314_100',
    price: loveCompositeHoodiePrice,
    link: 'love-composite-capsule-hoodie-111EE2J314_100.html',
    images: [
      fileLocation + '/love_hoodies_white_1.jpg',
      fileLocation + '/love_hoodies_white_2.jpg',
      fileLocation + '/love_hoodies_white_3.jpg',
      fileLocation + '/love_hoodies_white_4.jpg',
      fileLocation + '/love_hoodies_white_5.jpg',
    ],
  },
  {
    productId: '111EE2J314_250',
    price: loveCompositeHoodiePrice,
    link: 'love-composite-capsule-hoodie-111EE2J314_250.html',
    images: [
      fileLocation + '/love_hoodies_khaki_1.jpg',
      fileLocation + '/love_hoodies_khaki_2.jpg',
      fileLocation + '/love_hoodies_khaki_3.jpg',
      fileLocation + '/love_hoodies_khaki_4.jpg',
      fileLocation + '/love_hoodies_khaki_5.jpg',
    ],
  },
  {
    productId: '111EE2J314_430',
    price: loveCompositeHoodiePrice,
    link: 'love-composite-capsule-hoodie-111EE2J314_430.html',
    images: [
      fileLocation + '/love_hoodies_blue_1.jpg',
      fileLocation + '/love_hoodies_blue_2.jpg',
      fileLocation + '/love_hoodies_blue_3.jpg',
      fileLocation + '/love_hoodies_blue_4.jpg',
      fileLocation + '/love_hoodies_blue_5.jpg',
    ],
  },
  {
    productId: '111EE2J314_630',
    price: loveCompositeHoodiePrice,
    link: 'love-composite-capsule-hoodie-111EE2J314_630.html',
    images: [
      fileLocation + '/love_hoodies_red_1.jpg',
      fileLocation + '/love_hoodies_red_2.jpg',
      fileLocation + '/love_hoodies_red_3.jpg',
      fileLocation + '/love_hoodies_red_4.jpg',
      fileLocation + '/love_hoodies_red_5.jpg',
    ],
  },
  {
    productId: '111EE2J314_310',
    price: loveCompositeHoodiePrice,
    link: 'love-composite-capsule-hoodie-111EE2J314_310.html',
    images: [
      fileLocation + '/love_hoodies_green_1.jpg',
      fileLocation + '/love_hoodies_green_2.jpg',
      fileLocation + '/love_hoodies_green_3.jpg',
      fileLocation + '/love_hoodies_green_4.jpg',
      fileLocation + '/love_hoodies_green_5.jpg',
    ],
  },
  {
    productId: '111EE2J314_510',
    price: loveCompositeHoodiePrice,
    link: 'love-composite-capsule-hoodie-111EE2J314_510.html',
    images: [
      fileLocation + '/love_hoodies_purple_1.jpg',
      fileLocation + '/love_hoodies_purple_2.jpg',
      fileLocation + '/love_hoodies_purple_3.jpg',
      fileLocation + '/love_hoodies_purple_4.jpg',
      fileLocation + '/love_hoodies_purple_5.jpg',
    ],
  },
  {
    productId: '111EE2J314_820',
    price: loveCompositeHoodiePrice,
    link: 'love-composite-capsule-hoodie-111EE2J314_820.html',
    images: [
      fileLocation + '/love_hoodies_orange_1.jpg',
      fileLocation + '/love_hoodies_orange_2.jpg',
      fileLocation + '/love_hoodies_orange_3.jpg',
      fileLocation + '/love_hoodies_orange_4.jpg',
      fileLocation + '/love_hoodies_orange_5.jpg',
    ],
  },
];

const ColourTeeList: ProductMisc[] = [
  {
    productId: '111EE2K303_250',
    price: colorCapsuleTeePrice,
    link: 'color-capsule-t-shirt-111EE2K303_250.html',
    images: [
      fileLocation + '/colour_khaki_1.jpg',
      fileLocation + '/colour_khaki_2.jpg',
      fileLocation + '/colour_khaki_3.jpg',
      fileLocation + '/colour_khaki_4.jpg',
      fileLocation + '/colour_khaki_5.jpg',
    ],
  },
  {
    productId: '111EE2K303_300',
    price: colorCapsuleTeePrice,
    link: 'color-capsule-t-shirt-111EE2K303_300.html',
    images: [
      fileLocation + '/colour_green_1.jpg',
      fileLocation + '/colour_green_2.jpg',
      fileLocation + '/colour_green_3.jpg',
      fileLocation + '/colour_green_4.jpg',
      fileLocation + '/colour_green_5.jpg',
    ],
  },
  {
    productId: '111EE2K303_430',
    price: colorCapsuleTeePrice,
    link: 'color-capsule-t-shirt-111EE2K303_430.html',
    images: [
      fileLocation + '/colour_blue_1.jpg',
      fileLocation + '/colour_blue_2.jpg',
      fileLocation + '/colour_blue_3.jpg',
      fileLocation + '/colour_blue_4.jpg',
      fileLocation + '/colour_blue_5.jpg',
    ],
  },
  {
    productId: '111EE2K303_630',
    price: colorCapsuleTeePrice,
    link: 'color-capsule-t-shirt-111EE2K303_630.html',
    images: [
      fileLocation + '/colour_red_1.jpg',
      fileLocation + '/colour_red_2.jpg',
      fileLocation + '/colour_red_3.jpg',
      fileLocation + '/colour_red_4.jpg',
      fileLocation + '/colour_red_5.jpg',
    ],
  },
];

const ToteBagList: ProductMisc[] = [
  {
    productId: '111EA1O341_270',
    price: colorCapsuleToteBagPrice,
    link: 'color-capsule-tote-bag-111EA1O341_270.html',
    images: [
      fileLocation + '/colour_totebag_1.jpg',
      fileLocation + '/colour_totebag_2.jpg',
      fileLocation + '/colour_totebag_3.jpg',
      fileLocation + '/colour_totebag_4.jpg',
      fileLocation + '/colour_totebag_5.jpg',
    ],
  },
];

const RnRList: ProductMisc[] = [
  {
    productId: '042EE2G304_400',
    price: 0,
    link: 'esprit-x-rest-%26-recreation-capsule-color-block-windbreaker-jacket-042EE2G304_400.html',
    images: [
      fileLocation + '/R&R_Windbreaker_navy_1.jpg',
      fileLocation + '/R&R_Windbreaker_navy_2.jpg',
      fileLocation + '/R&R_Windbreaker_navy_3.jpg',
      fileLocation + '/R&R_Windbreaker_navy_4.jpg',
      fileLocation + '/R&R_Windbreaker_navy_5.jpg',
    ],
  },
  {
    productId: '042EE2G303_430',
    price: 0,
    link: 'esprit-x-rest-%26-recreation-capsule-color-block-windbreaker-jacket-042EE2G303_430.html',
    images: [
      fileLocation + '/R&R_Windbreaker_Colour_1.jpg',
      fileLocation + '/R&R_Windbreaker_Colour_2.jpg',
      fileLocation + '/R&R_Windbreaker_Colour_3.jpg',
      fileLocation + '/R&R_Windbreaker_Colour_4.jpg',
      fileLocation + '/R&R_Windbreaker_Colour_5.jpg',
    ],
  },
  {
    productId: '042EE1J313_034',
    // name: 'Sweatshirts',
    price: 0,
    link: 'sweatshirts-042EE1J313_034.html',
    images: [
      fileLocation + '/R&R_Sweatshirt_Hood_Grey_1.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_Grey_2.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_Grey_3.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_Grey_4.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_Grey_5.jpg',
    ],
  },
  {
    productId: '042EE1J313_100',
    // name: 'Sweatshirts',
    price: 0,
    link: 'sweatshirts-042EE1J313_100.html',
    images: [
      fileLocation + '/R&R_Sweatshirt_Hood_White_1.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_White_2.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_White_3.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_White_4.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_White_5.jpg',
    ],
  },
  {
    productId: '042EE1J313_570',
    // name: 'Sweatshirts',
    price: 0,
    link: 'sweatshirts-042EE1J313_570.html',
    images: [
      fileLocation + '/R&R_Sweatshirt_Hood_Lavender_1.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_Lavender_2.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_Lavender_3.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_Lavender_4.jpg',
      fileLocation + '/R&R_Sweatshirt_Hood_Lavender_5.jpg',
    ],
  },
  {
    productId: '042EE1J314_270',
    // name: 'Sweatshirts',
    price: 0,
    link: 'sweatshirts-042EE1J314_270.html',
    images: [
      fileLocation + '/R&R_Sweatshirt_Beige_1.jpg',
      fileLocation + '/R&R_Sweatshirt_Beige_2.jpg',
      fileLocation + '/R&R_Sweatshirt_Beige_3.jpg',
      fileLocation + '/R&R_Sweatshirt_Beige_4.jpg',
      fileLocation + '/R&R_Sweatshirt_Beige_5.jpg',
    ],
  },
  {
    productId: '042EE1J314_034',
    // name: 'Sweatshirts',
    price: 0,
    link: 'sweatshirts-042EE1J314_034.html',
    images: [
      fileLocation + '/R&R_Sweatshirt_Grey_1.jpg',
      fileLocation + '/R&R_Sweatshirt_Grey_2.jpg',
      fileLocation + '/R&R_Sweatshirt_Grey_3.jpg',
      fileLocation + '/R&R_Sweatshirt_Grey_4.jpg',
      fileLocation + '/R&R_Sweatshirt_Grey_5.jpg',
    ],
  },
  {
    productId: '042EE1J314_001',
    // name: 'Sweatshirts',
    price: 0,
    link: 'sweatshirts-042EE1J314_001.html',
    images: [
      fileLocation + '/R&R_Sweatshirt_Black_1.jpg',
      fileLocation + '/R&R_Sweatshirt_Black_2.jpg',
      fileLocation + '/R&R_Sweatshirt_Black_3.jpg',
      fileLocation + '/R&R_Sweatshirt_Black_4.jpg',
      fileLocation + '/R&R_Sweatshirt_Black_5.jpg',
    ],
  },
  {
    productId: '042EE1I321_670',
    // name: 'Sweaters',
    price: 0,
    link: 'sweaters-042EE1I321_670.html',
    images: [
      fileLocation + '/R&R_Sleeveless_jumper_Pink_1.jpg',
      fileLocation + '/R&R_Sleeveless_jumper_Pink_2.jpg',
      fileLocation + '/R&R_Sleeveless_jumper_Pink_3.jpg',
      fileLocation + '/R&R_Sleeveless_jumper_Pink_4.jpg',
      fileLocation + '/R&R_Sleeveless_jumper_Pink_5.jpg',
    ],
  },
  {
    productId: '042EE1I321_034',
    // name: 'Sweaters',
    price: 0,
    link: 'sweaters-042EE1I321_034.html',
    images: [
      fileLocation + '/R&R_Sleeveless_jumper_Grey_1.jpg',
      fileLocation + '/R&R_Sleeveless_jumper_Grey_2.jpg',
      fileLocation + '/R&R_Sleeveless_jumper_Grey_3.jpg',
      fileLocation + '/R&R_Sleeveless_jumper_Grey_4.jpg',
      fileLocation + '/R&R_Sleeveless_jumper_Grey_5.jpg',
    ],
  },
  {
    productId: '042EE1I322_630',
    // name: 'Sweaters',
    price: 0,
    link: 'sweaters-042EE1I322_630.html',
    images: [
      fileLocation + '/R&R_Jumper_Red_1.jpg',
      fileLocation + '/R&R_Jumper_Red_2.jpg',
      fileLocation + '/R&R_Jumper_Red_3.jpg',
      fileLocation + '/R&R_Jumper_Red_4.jpg',
      fileLocation + '/R&R_Jumper_Red_5.jpg',
    ],
  },
  {
    productId: '042EE1K398_100',
    // name: 'T-shirts',
    price: 0,
    link: 't-shirts-042EE1K398_100.html',
    images: [
      fileLocation + '/R&R_Cropped_tshirt_White_1.jpg',
      fileLocation + '/R&R_Cropped_tshirt_White_2.jpg',
      fileLocation + '/R&R_Cropped_tshirt_White_3.jpg',
      fileLocation + '/R&R_Cropped_tshirt_White_4.jpg',
      fileLocation + '/R&R_Cropped_tshirt_White_5.jpg',
    ],
  },
  {
    productId: '042EE1K398_001',
    // name: 'T-shirts',
    price: 0,
    link: 't-shirts-042EE1K398_001.html',
    images: [
      fileLocation + '/R&R_Cropped_tshirt_Black_1.jpg',
      fileLocation + '/R&R_Cropped_tshirt_Black_2.jpg',
      fileLocation + '/R&R_Cropped_tshirt_Black_3.jpg',
      fileLocation + '/R&R_Cropped_tshirt_Black_4.jpg',
      fileLocation + '/R&R_Cropped_tshirt_Black_5.jpg',
    ],
  },
  {
    productId: '042EE1F339_100',
    price: 0,
    link: 'esprit-x-rest-%26-recreation-capsule-oxford-shirt-042EE1F339_100.html',
    images: [
      fileLocation + '/R&R_Oxford_shirt_White_1.jpg',
      fileLocation + '/R&R_Oxford_shirt_White_2.jpg',
      fileLocation + '/R&R_Oxford_shirt_White_3.jpg',
      fileLocation + '/R&R_Oxford_shirt_White_4.jpg',
      fileLocation + '/R&R_Oxford_shirt_White_5.jpg',
    ],
  },
  {
    productId: '042EE1F339_670',
    price: 0,
    link: 'esprit-x-rest-%26-recreation-capsule-oxford-shirt-042EE1F339_670.html',
    images: [
      fileLocation + '/R&R_Oxford_shirt_Pink_1.jpg',
      fileLocation + '/R&R_Oxford_shirt_Pink_2.jpg',
      fileLocation + '/R&R_Oxford_shirt_Pink_3.jpg',
      fileLocation + '/R&R_Oxford_shirt_Pink_4.jpg',
      fileLocation + '/R&R_Oxford_shirt_Pink_5.jpg',
    ],
  },
  {
    productId: '042EE1F339_430',
    price: 0,
    link: 'esprit-x-rest-%26-recreation-capsule-oxford-shirt-042EE1F339_430.html',
    images: [
      fileLocation + '/R&R_Oxford_shirt_Blue_1.jpg',
      fileLocation + '/R&R_Oxford_shirt_Blue_2.jpg',
      fileLocation + '/R&R_Oxford_shirt_Blue_3.jpg',
      fileLocation + '/R&R_Oxford_shirt_Blue_4.jpg',
      fileLocation + '/R&R_Oxford_shirt_Blue_5.jpg',
    ],
  },
  {
    productId: '042EE1C331_310',
    price: 0,
    link: 'esprit-x-rest-%26-recreation-capsule-windbreaker-shorts-042EE1C331_310.html',
    images: [
      fileLocation + '/R&R_Shorts_Green_1.jpg',
      fileLocation + '/R&R_Shorts_Green_2.jpg',
      fileLocation + '/R&R_Shorts_Green_3.jpg',
      fileLocation + '/R&R_Shorts_Green_4.jpg',
      fileLocation + '/R&R_Shorts_Green_5.jpg',
    ],
  },
  {
    productId: '042EE1C331_001',
    price: 0,
    link: 'esprit-x-rest-%26-recreation-capsule-windbreaker-shorts-042EE1C331_001.html',
    images: [
      fileLocation + '/R&R_Shorts_Black_1.jpg',
      fileLocation + '/R&R_Shorts_Black_2.jpg',
      fileLocation + '/R&R_Shorts_Black_3.jpg',
      fileLocation + '/R&R_Shorts_Black_4.jpg',
      fileLocation + '/R&R_Shorts_Black_5.jpg',
    ],
  },
  {
    productId: '042EE1B346_270',
    // name: 'Pants knitted',
    price: 0,
    link: 'pants-knitted-042EE1B346_270.html',
    images: [
      fileLocation + '/R&R_Tracksuit_Bottom_Beige_1.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Beige_2.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Beige_3.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Beige_4.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Beige_5.jpg',
    ],
  },
  {
    productId: '042EE1B346_001',
    // name: 'Pants knitted',
    price: 0,
    link: 'pants-knitted-042EE1B346_001.html',
    images: [
      fileLocation + '/R&R_Tracksuit_Bottom_Black_1.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Black_2.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Black_3.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Black_4.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Black_5.jpg',
    ],
  },
  {
    productId: '042EE1B346_034',
    // name: 'Pants knitted',
    price: 0,
    link: 'pants-knitted-042EE1B346_034.html',
    images: [
      fileLocation + '/R&R_Tracksuit_Bottom_Grey_1.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Grey_2.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Grey_3.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Grey_4.jpg',
      fileLocation + '/R&R_Tracksuit_Bottom_Grey_5.jpg',
    ],
  },
  {
    productId: '042EE1B345_900',
    price: 0,
    link: 'esprit-x-rest-%26-recreation-capsule-denim-cargo-pants-%28size-24-30%29-042EE1B345_900.html',
    images: [
      fileLocation + '/R&R_Denim_pants_1.jpg',
      fileLocation + '/R&R_Denim_pants_2.jpg',
      fileLocation + '/R&R_Denim_pants_3.jpg',
      fileLocation + '/R&R_Denim_pants_4.jpg',
      fileLocation + '/R&R_Denim_pants_5.jpg',
    ],
  },
  {
    productId: '042EE2B310_900',
    price: 0,
    link: 'esprit-x-rest-%26-recreation-capsule-denim-cargo-pants-%28size-32-36%29-042EE2B310_900.html',
    images: [
      fileLocation + '/R&R_Denim_pants_1.jpg',
      fileLocation + '/R&R_Denim_pants_2.jpg',
      fileLocation + '/R&R_Denim_pants_3.jpg',
      fileLocation + '/R&R_Denim_pants_4.jpg',
      fileLocation + '/R&R_Denim_pants_5.jpg',
    ],
  },
  {
    productId: '042EE1B347_270',
    // name: 'Pants woven',
    price: 0,
    link: 'pants-woven-042EE1B347_270.html',
    images: [
      fileLocation + '/R&R_Cargo_trousers_Beige_1.jpg',
      fileLocation + '/R&R_Cargo_trousers_Beige_2.jpg',
      fileLocation + '/R&R_Cargo_trousers_Beige_3.jpg',
      fileLocation + '/R&R_Cargo_trousers_Beige_4.jpg',
      fileLocation + '/R&R_Cargo_trousers_Beige_5.jpg',
    ],
  },
  {
    productId: '042EE2B311_270',
    // name: 'Pants woven',
    price: 0,
    link: 'pants-woven-042EE2B311_270.html',
    images: [
      fileLocation + '/R&R_Cargo_trousers_Beige_1.jpg',
      fileLocation + '/R&R_Cargo_trousers_Beige_2.jpg',
      fileLocation + '/R&R_Cargo_trousers_Beige_3.jpg',
      fileLocation + '/R&R_Cargo_trousers_Beige_4.jpg',
      fileLocation + '/R&R_Cargo_trousers_Beige_5.jpg',
    ],
  },
  {
    productId: '042EA1O360_290',
    // name: 'Bags',
    price: 0,
    link: 'bags-042EA1O360_290.html',
    images: [
      fileLocation + '/R&R_Dufflebag_Small_Light_beige_1.jpg',
      fileLocation + '/R&R_Dufflebag_Small_Light_beige_2.jpg',
      fileLocation + '/R&R_Dufflebag_Small_Light_beige_3.jpg',
    ],
    isOneSize: true,
  },
  {
    productId: '042EA1O360_750',
    // name: 'Bags',
    price: 0,
    link: 'bags-042EA1O360_750.html',
    images: [
      fileLocation + '/R&R_Dufflebag_Small_Bright_orange_1.jpg',
      fileLocation + '/R&R_Dufflebag_Small_Bright_orange_2.jpg',
      fileLocation + '/R&R_Dufflebag_Small_Bright_orange_3.jpg',
    ],
    isOneSize: true,
  },
  {
    productId: '042EA1O361_400',
    // name: 'Bags',
    price: 0,
    link: 'bags-042EA1O361_400.html',
    images: [
      fileLocation + '/R&R_Dufflebag_Large_Navy_1.jpg',
      fileLocation + '/R&R_Dufflebag_Large_Navy_2.jpg',
      fileLocation + '/R&R_Dufflebag_Large_Navy_3.jpg',
    ],
    isOneSize: true,
  },
  {
    productId: '042EA1O361_290',
    // name: 'Bags',
    price: 0,
    link: 'bags-042EA1O361_290.html',
    images: [
      fileLocation + '/R&R_Dufflebag_Light_beige_1.jpg',
      fileLocation + '/R&R_Dufflebag_Light_beige_2.jpg',
      fileLocation + '/R&R_Dufflebag_Light_beige_3.jpg',
    ],
    isOneSize: true,
  },
  {
    productId: '042EA1P328_001',
    // name: 'Hats/Caps',
    price: 0,
    link: 'hats%2Fcaps-042EA1P328_001.html',
    images: [fileLocation + '/R&R_Cap_Black_1.jpg', fileLocation + '/R&R_Cap_Black_2.jpg', fileLocation + '/R&R_Cap_Black_3.jpg'],
  },
  {
    productId: '042EA1P328_290',
    // name: 'Hats/Caps',
    price: 0,
    link: 'hats%2Fcaps-042EA1P328_290.html',
    images: [
      fileLocation + '/R&R_Cap_Light_beige_1.jpg',
      fileLocation + '/R&R_Cap_Light_beige_2.jpg',
      fileLocation + '/R&R_Cap_Light_beige_3.jpg',
    ],
  },
];

const ColourDolphinList: ProductMisc[] = [
  //color-dolphin-classic-polo women
  {
    productId: '052EE1K375_001',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE1K375_001.html',
    images: [
      fileLocation + '/Esprit_CDP_Classic_Black_1.jpg',
      fileLocation + '/Esprit_CDP_Classic_Black_2.jpg',
      fileLocation + '/Esprit_CDP_Classic_Black_3.jpg',
      fileLocation + '/Esprit_CDP_Classic_Black_4.jpg',
      fileLocation + '/Esprit_CDP_Classic_Black_5.jpg',
    ],
  },
  {
    productId: '052EE1K375_100',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE1K375_100.html',
    images: [
      fileLocation + '/Esprit_CDP_Classic_White_1.jpg',
      fileLocation + '/Esprit_CDP_Classic_White_2.jpg',
      fileLocation + '/Esprit_CDP_Classic_White_3.jpg',
      fileLocation + '/Esprit_CDP_Classic_White_4.jpg',
      fileLocation + '/Esprit_CDP_Classic_White_5.jpg',
    ],
  },
  {
    productId: '052EE1K375_310',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE1K375_310.html',
    images: [
      fileLocation + '/Esprit_CDP_Classic_Green_1.jpg',
      fileLocation + '/Esprit_CDP_Classic_Green_2.jpg',
      fileLocation + '/Esprit_CDP_Classic_Green_3.jpg',
      fileLocation + '/Esprit_CDP_Classic_Green_4.jpg',
      fileLocation + '/Esprit_CDP_Classic_Green_5.jpg',
    ],
  },
  {
    productId: '052EE1K375_400',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE1K375_400.html',
    images: [
      fileLocation + '/Esprit_CDP_Classic_Navy_1.jpg',
      fileLocation + '/Esprit_CDP_Classic_Navy_2.jpg',
      fileLocation + '/Esprit_CDP_Classic_Navy_3.jpg',
      fileLocation + '/Esprit_CDP_Classic_Navy_4.jpg',
      fileLocation + '/Esprit_CDP_Classic_Navy_5.jpg',
    ],
  },
  {
    productId: '052EE1K375_430',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE1K375_430.html',
    images: [
      fileLocation + '/Esprit_CDP_Classic_Blue_1.jpg',
      fileLocation + '/Esprit_CDP_Classic_Blue_2.jpg',
      fileLocation + '/Esprit_CDP_Classic_Blue_3.jpg',
      fileLocation + '/Esprit_CDP_Classic_Blue_4.jpg',
      fileLocation + '/Esprit_CDP_Classic_Blue_5.jpg',
    ],
  },
  {
    productId: '052EE1K375_630',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE1K375_630.html',
    images: [
      fileLocation + '/Esprit_CDP_Classic_Red_1.jpg',
      fileLocation + '/Esprit_CDP_Classic_Red_2.jpg',
      fileLocation + '/Esprit_CDP_Classic_Red_3.jpg',
      fileLocation + '/Esprit_CDP_Classic_Red_4.jpg',
      fileLocation + '/Esprit_CDP_Classic_Red_5.jpg',
    ],
  },
  {
    productId: '052EE1K375_670',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE1K375_670.html',
    images: [
      fileLocation + '/Esprit_CDP_Classic_Pink_1.jpg',
      fileLocation + '/Esprit_CDP_Classic_Pink_2.jpg',
      fileLocation + '/Esprit_CDP_Classic_Pink_3.jpg',
      fileLocation + '/Esprit_CDP_Classic_Pink_4.jpg',
      fileLocation + '/Esprit_CDP_Classic_Pink_5.jpg',
    ],
  },
  {
    productId: '052EE1K375_820',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE1K375_820.html',
    images: [
      fileLocation + '/Esprit_CDP_Classic_Orange_1.jpg',
      fileLocation + '/Esprit_CDP_Classic_Orange_2.jpg',
      fileLocation + '/Esprit_CDP_Classic_Orange_3.jpg',
      fileLocation + '/Esprit_CDP_Classic_Orange_4.jpg',
      fileLocation + '/Esprit_CDP_Classic_Orange_5.jpg',
    ],
  },
  //color-dolphin-classic-polo men
  {
    productId: '052EE2K325_001',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE2K325_001.html',
    images: [
      fileLocation + '/Esprit_CDP_Relax_Black_1.jpg',
      fileLocation + '/Esprit_CDP_Relax_Black_2.jpg',
      fileLocation + '/Esprit_CDP_Relax_Black_3.jpg',
      fileLocation + '/Esprit_CDP_Relax_Black_4.jpg',
      fileLocation + '/Esprit_CDP_Relax_Black_5.jpg',
    ],
  },
  {
    productId: '052EE2K325_100',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE2K325_100.html',
    images: [
      fileLocation + '/Esprit_CDP_Relax_White_1.jpg',
      fileLocation + '/Esprit_CDP_Relax_White_2.jpg',
      fileLocation + '/Esprit_CDP_Relax_White_3.jpg',
      fileLocation + '/Esprit_CDP_Relax_White_4.jpg',
      fileLocation + '/Esprit_CDP_Relax_White_5.jpg',
    ],
  },
  {
    productId: '052EE2K325_310',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE2K325_310.html',
    images: [
      fileLocation + '/Esprit_CDP_Relax_Green_1.jpg',
      fileLocation + '/Esprit_CDP_Relax_Green_2.jpg',
      fileLocation + '/Esprit_CDP_Relax_Green_3.jpg',
      fileLocation + '/Esprit_CDP_Relax_Green_4.jpg',
      fileLocation + '/Esprit_CDP_Relax_Green_5.jpg',
    ],
  },
  {
    productId: '052EE2K325_405',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE2K325_405.html',
    images: [
      fileLocation + '/Esprit_CDP_Relax_Navy_1.jpg',
      fileLocation + '/Esprit_CDP_Relax_Navy_2.jpg',
      fileLocation + '/Esprit_CDP_Relax_Navy_3.jpg',
      fileLocation + '/Esprit_CDP_Relax_Navy_4.jpg',
      fileLocation + '/Esprit_CDP_Relax_Navy_5.jpg',
    ],
  },
  {
    productId: '052EE2K325_430',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE2K325_430.html',
    images: [
      fileLocation + '/Esprit_CDP_Relax_Blue_1.jpg',
      fileLocation + '/Esprit_CDP_Relax_Blue_2.jpg',
      fileLocation + '/Esprit_CDP_Relax_Blue_3.jpg',
      fileLocation + '/Esprit_CDP_Relax_Blue_4.jpg',
      fileLocation + '/Esprit_CDP_Relax_Blue_5.jpg',
    ],
  },
  {
    productId: '052EE2K325_630',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE2K325_630.html',
    images: [
      fileLocation + '/Esprit_CDP_Relax_Red_1.jpg',
      fileLocation + '/Esprit_CDP_Relax_Red_2.jpg',
      fileLocation + '/Esprit_CDP_Relax_Red_3.jpg',
      fileLocation + '/Esprit_CDP_Relax_Red_4.jpg',
      fileLocation + '/Esprit_CDP_Relax_Red_5.jpg',
    ],
  },
  {
    productId: '052EE2K325_670',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE2K325_670.html',
    images: [
      fileLocation + '/Esprit_CDP_Relax_Pink_1.jpg',
      fileLocation + '/Esprit_CDP_Relax_Pink_2.jpg',
      fileLocation + '/Esprit_CDP_Relax_Pink_3.jpg',
      fileLocation + '/Esprit_CDP_Relax_Pink_4.jpg',
      fileLocation + '/Esprit_CDP_Relax_Pink_5.jpg',
    ],
  },
  {
    productId: '052EE2K325_820',
    price: 0,
    link: 'color-dolphin-classic-polo-052EE2K325_820.html',
    images: [
      fileLocation + '/Esprit_CDP_Relax_Orange_1.jpg',
      fileLocation + '/Esprit_CDP_Relax_Orange_2.jpg',
      fileLocation + '/Esprit_CDP_Relax_Orange_3.jpg',
      fileLocation + '/Esprit_CDP_Relax_Orange_4.jpg',
      fileLocation + '/Esprit_CDP_Relax_Orange_5.jpg',
    ],
  },
  //color-dolphin-classic-polo-dress
  {
    productId: '052EE1E350_400',
    price: 0,
    link: 'color-dolphin-classic-polo-dress-052EE1E350_400.html',
    images: [
      fileLocation + '/Esprit_CDP_Dress_Navy_1.jpg',
      fileLocation + '/Esprit_CDP_Dress_Navy_2.jpg',
      fileLocation + '/Esprit_CDP_Dress_Navy_3.jpg',
      fileLocation + '/Esprit_CDP_Dress_Navy_4.jpg',
      fileLocation + '/Esprit_CDP_Dress_Navy_5.jpg',
    ],
  },
  {
    productId: '052EE1E350_001',
    price: 0,
    link: 'color-dolphin-classic-polo-dress-052EE1E350_001.html',
    images: [
      fileLocation + '/Esprit_CDP_Dress_Black_1.jpg',
      fileLocation + '/Esprit_CDP_Dress_Black_2.jpg',
      fileLocation + '/Esprit_CDP_Dress_Black_3.jpg',
      fileLocation + '/Esprit_CDP_Dress_Black_4.jpg',
      fileLocation + '/Esprit_CDP_Dress_Black_5.jpg',
    ],
  },
  {
    productId: '052EE1E350_100',
    price: 0,
    link: 'color-dolphin-classic-polo-dress-052EE1E350_100.html',
    images: [
      fileLocation + '/Esprit_CDP_Dress_White_1.jpg',
      fileLocation + '/Esprit_CDP_Dress_White_2.jpg',
      fileLocation + '/Esprit_CDP_Dress_White_3.jpg',
      fileLocation + '/Esprit_CDP_Dress_White_4.jpg',
      fileLocation + '/Esprit_CDP_Dress_White_5.jpg',
    ],
  },
  {
    productId: '052EE1E350_630',
    price: 0,
    link: 'color-dolphin-classic-polo-dress-052EE1E350_630.html',
    images: [
      fileLocation + '/Esprit_CDP_Dress_Red_1.jpg',
      fileLocation + '/Esprit_CDP_Dress_Red_2.jpg',
      fileLocation + '/Esprit_CDP_Dress_Red_3.jpg',
      fileLocation + '/Esprit_CDP_Dress_Red_4.jpg',
      fileLocation + '/Esprit_CDP_Dress_Red_5.jpg',
    ],
  },
  {
    productId: '052EE1E350_670',
    price: 0,
    link: 'color-dolphin-classic-polo-dress-052EE1E350_670.html',
    images: [
      fileLocation + '/Esprit_CDP_Dress_Pink_1.jpg',
      fileLocation + '/Esprit_CDP_Dress_Pink_2.jpg',
      fileLocation + '/Esprit_CDP_Dress_Pink_3.jpg',
      fileLocation + '/Esprit_CDP_Dress_Pink_4.jpg',
      fileLocation + '/Esprit_CDP_Dress_Pink_5.jpg',
    ],
  },
  //color-dolphin-pleated-polo-dress
  {
    productId: '052EE1E351_001',
    price: 0,
    link: 'color-dolphin-pleated-polo-dress-052EE1E351_001.html',
    images: [
      fileLocation + '/Esprit_CDP_PleatedDress_Black_1.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_Black_2.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_Black_3.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_Black_4.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_Black_5.jpg',
    ],
  },
  {
    productId: '052EE1E351_100',
    price: 0,
    link: 'color-dolphin-pleated-polo-dress-052EE1E351_100.html',
    images: [
      fileLocation + '/Esprit_CDP_PleatedDress_White_1.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_White_2.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_White_3.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_White_4.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_White_5.jpg',
    ],
  },
  {
    productId: '052EE1E351_670',
    price: 0,
    link: 'color-dolphin-pleated-polo-dress-052EE1E351_670.html',
    images: [
      fileLocation + '/Esprit_CDP_PleatedDress_Pink_1.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_Pink_2.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_Pink_3.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_Pink_4.jpg',
      fileLocation + '/Esprit_CDP_PleatedDress_Pink_5.jpg',
    ],
  },
  //color-dolphin-relaxed-polo men
  {
    productId: '052EE2K326_001',
    price: 0,
    link: 'color-dolphin-relaxed-polo-052EE2K326_001.html',
    images: [
      fileLocation + '/Relax_Polo_Black_1.jpg',
      fileLocation + '/Relax_Polo_Black_2.jpg',
      fileLocation + '/Relax_Polo_Black_3.jpg',
      fileLocation + '/Relax_Polo_Black_4.jpg',
      fileLocation + '/Relax_Polo_Black_5.jpg',
    ],
  },
  {
    productId: '052EE2K326_100',
    price: 0,
    link: 'color-dolphin-relaxed-polo-052EE2K326_100.html',
    images: [
      fileLocation + '/Relax_Polo_White_1.jpg',
      fileLocation + '/Relax_Polo_White_2.jpg',
      fileLocation + '/Relax_Polo_White_3.jpg',
      fileLocation + '/Relax_Polo_White_4.jpg',
      fileLocation + '/Relax_Polo_White_5.jpg',
    ],
  },
  {
    productId: '052EE2K326_310',
    price: 0,
    link: 'color-dolphin-relaxed-polo-052EE2K326_310.html',
    images: [
      fileLocation + '/Relax_Polo_Green_1.jpg',
      fileLocation + '/Relax_Polo_Green_2.jpg',
      fileLocation + '/Relax_Polo_Green_3.jpg',
      fileLocation + '/Relax_Polo_Green_4.jpg',
      fileLocation + '/Relax_Polo_Green_5.jpg',
    ],
  },
  {
    productId: '052EE2K326_430',
    price: 0,
    link: 'color-dolphin-relaxed-polo-052EE2K326_430.html',
    images: [
      fileLocation + '/Relax_Polo_Blue_1.jpg',
      fileLocation + '/Relax_Polo_Blue_2.jpg',
      fileLocation + '/Relax_Polo_Blue_3.jpg',
      fileLocation + '/Relax_Polo_Blue_4.jpg',
      fileLocation + '/Relax_Polo_Blue_5.jpg',
    ],
  },
  // //color-dolphin-cropped-t-shirt women
  // {
  //   productId: '042EE1K397_750',
  //   price: 0,
  //   link: 'color-dolphin-cropped-t-shirt-042EE1K397_750.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1K397_001',
  //   price: 0,
  //   link: 'color-dolphin-cropped-t-shirt-042EE1K397_750.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1K397_310',
  //   price: 0,
  //   link: 'color-dolphin-cropped-t-shirt-042EE1K397_310.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1K397_335',
  //   price: 0,
  //   link: 'color-dolphin-cropped-t-shirt-042EE1K397_335.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1K397_430',
  //   price: 0,
  //   link: 'color-dolphin-cropped-t-shirt-042EE1K397_430.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1K397_660',
  //   price: 0,
  //   link: 'color-dolphin-cropped-t-shirt-042EE1K397_660.html',
  //   images: [],
  // },
  // //color-dolphin-relaxed-fit-t-shirt men
  // {
  //   productId: '042EE2K333_050',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-t-shirt-042EE2K333_050.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2K333_002',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-t-shirt-042EE2K333_002.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2K333_310',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-t-shirt-042EE2K333_310.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2K333_430',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-t-shirt-042EE2K333_430.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2K333_660',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-t-shirt-042EE2K333_660.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2K333_750',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-t-shirt-042EE2K333_750.html',
  //   images: [],
  // },
  // //color-dolphin-sleeveless-hoodie men
  // {
  //   productId: '042EE2J311_050',
  //   price: 0,
  //   link: 'color-dolphin-sleeveless-hoodie-042EE2J311_050.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2J311_285',
  //   price: 0,
  //   link: 'color-dolphin-sleeveless-hoodie-042EE2J311_285.html',
  //   images: [],
  // },
  // //color-dolphin-sleeveless-hoodie women
  // {
  //   productId: '042EE1J312_285',
  //   price: 0,
  //   link: 'color-dolphin-sleeveless-hoodie-042EE1J312_285.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1J312_335',
  //   price: 0,
  //   link: 'color-dolphin-sleeveless-hoodie-042EE1J312_335.html',
  //   images: [],
  // },
  // //color-dolphin-relaxed-fit-t-shirt-dress women
  // {
  //   productId: '042EE1E353_670',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-t-shirt-dress-042EE1E353_670.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1E353_001',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-t-shirt-dress-042EE1E353_001.html',
  //   images: [],
  // },
  // //color-dolphin-relaxed-fit-sweatshirt men
  // {
  //   productId: '042EE2J312_430',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-sweatshirt-042EE2J312_430.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2J312_002',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-sweatshirt-042EE2J312_002.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2J312_050',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-sweatshirt-042EE2J312_050.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2J312_660',
  //   price: 0,
  //   link: 'color-dolphin-relaxed-fit-sweatshirt-042EE2J312_660.html',
  //   images: [],
  // },
  // //color-dolphin-cropped-sweatshirt women
  // {
  //   productId: '042EE1J311_430',
  //   price: 0,
  //   link: 'color-dolphin-cropped-sweatshirt-042EE1J311_430.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1J311_001',
  //   price: 0,
  //   link: 'color-dolphin-cropped-sweatshirt-042EE1J311_001.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1J311_335',
  //   price: 0,
  //   link: 'color-dolphin-cropped-sweatshirt-042EE1J311_335.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1J311_660',
  //   price: 0,
  //   link: 'color-dolphin-cropped-sweatshirt-042EE1J311_660.html',
  //   images: [],
  // },
  // //color-dolphin-drawstring-shorts men
  // {
  //   productId: '042EE2C322_285',
  //   price: 0,
  //   link: 'color-dolphin-drawstring-shorts-042EE2C322_285.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2C322_002',
  //   price: 0,
  //   link: 'color-dolphin-drawstring-shorts-042EE2C322_002.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2C322_050',
  //   price: 0,
  //   link: 'color-dolphin-drawstring-shorts-042EE2C322_050.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE2C322_430',
  //   price: 0,
  //   link: 'color-dolphin-drawstring-shorts-042EE2C322_430.html',
  //   images: [],
  // },
  // //color-dolphin-drawstring-shorts men
  // {
  //   productId: '042EE2C322_285',
  //   price: 0,
  //   link: 'color-dolphin-drawstring-shorts-042EE2C322_285.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1C330_001',
  //   price: 0,
  //   link: 'color-dolphin-drawstring-shorts-042EE1C330_001.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1C330_335',
  //   price: 0,
  //   link: 'color-dolphin-drawstring-shorts-042EE1C330_335.html',
  //   images: [],
  // },
  // {
  //   productId: '042EE1C330_660',
  //   price: 0,
  //   link: 'color-dolphin-drawstring-shorts-042EE1C330_660.html',
  //   images: [],
  // },
];


const ProductMiscList: ProductMisc[] = ReIssueColourTeeList.concat(ColourTeeList)
  .concat(LoveCompositeTeeList)
  .concat(ReIssueColourSweatList)
  .concat(LoveCompositeHoodieList)
  .concat(ToteBagList)
  .concat(RnRList)
  .concat(ColourDolphinList);
export { ColourList, Currencys, Languages, oneSize, shareLink, sizes, ProductMiscList };
