const Translation = {
  en: {
    translation: {
      'Colour': undefined,
      'Discover': 'DISCOVER',
      'Discover Description': 'Click to see product details and shop online',
      'Explore Now': 'EXPLORE NOW',
      'Learn More': 'Shop Now',
      'Navigation': 'NAVIGATION',
      'Navigation Description': 'Click and drag to look around',
      'Product': 'PRODUCT',
      'Product Description': 'Click to see details and shop online',
      'Size': undefined,
      'Welcome Description' : 'At ESPRIT\'s official virtual boutique, everything goes your way. Come on in and experiment with our colorful playbox of comfortable capsules!',
      'Welcome Message' : 'The ESPRIT Boutique',
    }
  },
  zh: {
    translation: {
      'Colour': '顏色',
      'Discover': '發現',
      'Discover Description': '點擊查看產品詳情並在線購物',
      'Explore Now': '立即探索',
      'Learn More': '了解更多',
      'Navigation': '導航',
      'Navigation Description': '單擊並拖動以環顧四周',
      'Product': '產品',
      'Product Description': '點擊查看詳情並在線購物',
      'Size': '尺寸',
      'Welcome Description' : '顏色。積極的。前瞻性思維。歡迎來到 Esprit 官方虛擬商店。探索時尚充滿活力的一面。',
      'Welcome Message' : 'ESPRIT 虛擬商店',
    }
  },
};

export default Translation;
