import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Content from './components/Content';
import Product from './pages/Product';
import { ThemeProvider } from 'styled-components';
import { Theme } from './Theme';
import Cart from './pages/Cart';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Translations from './Translations';
import { useEffect } from 'react';

const AppContainer = styled.div`
  width: 100%;
  /* min-height: 100vh; */
`;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: Translations,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

let loadCookie = async () => {
  let response = await fetch(window.location.hostname === 'boutique.esprit.hk' ? 'https://esprit.hk' : 'https://fibrestaging.esprit.hk',);

  console.log('response headers', response.headers);
  console.log('response header entries', response.headers.entries());
  let cookiesHeader = response.headers.get('set-cookie');
  console.log('cookies header', cookiesHeader);
  {
    let iter = response.headers.entries();
    let e;
    while (!(e = iter.next()).done) console.log('h', e.value);
  }
  let iter = response.headers.entries();
  let setCookies: string[] = [];
  let e;
  while (!(e = iter.next()).done) if (e[0].toLowerCase() === 'set-cookie') setCookies.push(e[1]);
  console.log('cookies', setCookies);

  let cookies = Object.fromEntries(setCookies
    .map(cookie => cookie.split(';')[0])
    .map(cookie => cookie.split('='))
    .map(([k, v]) => [decodeURIComponent(k.trim()), decodeURIComponent(v.trim())]));
  let { dwanonymous, dwsid, dwsecuretoken } = cookies;
  console.log('dw auth', { dwanonymous, dwsid, dwsecuretoken });
};

function App() {
  useEffect(() => {
    // document.domain = window.location.hostname === 'boutique.esprit.hk' ? 'esprit.hk' : 'esprit.dev';
    // loadCookie().catch(console.error);
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <AppContainer>
        <Routes>
          <Route path='/' element={<Content />} />
          <Route path='/product/:productId' element={<Product />} />
        </Routes>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
