import styled from 'styled-components';
import LocaleSelector from './LocaleSelector';
import EspritLogo from '../images/esprit_logo.png';
import ShareIcon from '../images/share.png';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import ShareFacebookIcon from '../images/share-facebook.png';
import ShareTwitterIcon from '../images/share-twitter.png';
import ShareCopyLinkIcon from '../images/share-copy-link.png';
import Card from './Card';
import { shareLink } from '../Constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-bottom: 100px;
  > div {
    width: 150px;
  }

  @media (max-width: 768px) {
    top: 0;
    position: sticky;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 50px;
    > div {
      width: 70px;
    }
  }
`;

const LeftButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 40px;
  }
  @media (max-width: 768px) {
    > *:not(:last-child) {
      margin-right: 10px;
    }
    justify-content: flex-start;
  }
`;

const RightButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 40px;
  }
  @media (max-width: 768px) {
    align-items: center;
    justify-content: flex-end;

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const ShareButton = styled.img`
  background-color: transparent;
  width: 28px;
  height: 30px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 22px;
    height: 23px;
  }
`;

const Button = styled.img`
  background-color: transparent;
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
  }
`;

const ESPRIT = styled.img`
  width: 135px;
  height: 38px;

  @media (max-width: 768px) {
    width: 106px;
    height: 30px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 0;
  height: 36px;
  width: 36px;
  border-radius: 0%;
  cursor: pointer;

  > div {
    align-items: flex-end;
  }

  @media (max-width: 768px) {
    height: 20px;
    width: 20px;
    justify-content: flex-start;
  }
`;

const Dropdown = styled(Card)`
  box-sizing: border-box;
  position: absolute;
  top: 30px;
  right: -8px;
  margin: 5px;
  padding: 8px;
  transition: all 0s ease 100ms;
  z-index: 0;

  @media (max-width: 768px) {
    top: 20px;
    right: -15px;
  }
`;

const ShareLink = styled.div`
  display: flex;
  flex-direction: row;

`;

const CopiedLink = styled.div`
  height: 18px;
  color: #FFFFFF;
  font-family: "Suisse Intl";
  size: 14px;
  font-weight: 300;
  padding: 4px 10px;
  text-align: center;
  border-radius: 13px;
  background-color: rgba(216,216,216,0.35);
  margin-right: 5px;
`;


const DropdownMenu = styled(Dropdown)<any>`
  > div {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    height: 45px;
    width: 100px;
    size: 18px;
    font-weight: 300;
    font-family: 'Suisse Intl';
    white-space: nowrap;
    text-decoration: none;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      height: 35px;
      width: 80px;
      font-size: 16px;
    }
  }

  :hover {
    cursor: pointer;
  }

  > div::after {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  visibility: ${(props) => props.dropDownOpen ? 'visible' : 'hidden' };
  transition-delay: 0s;

  /* ${Item}:hover & > *:hover {
    color: ${(props) => '#000000'};
    background-color: ${(props) => '#FFFFFF'};
  } */
`;

const LeftButtonGroup = () => {
  return (
    <LeftButtonGroupContainer>
      <LocaleSelector />
    </LeftButtonGroupContainer>
  );
};

const RightButtonGroup = () => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const[ openDropDown, setOpenDropDown] = useState(false);
  const onCopyHandler = () => {
    setCopied(false);
    navigator.clipboard.writeText(`${shareLink}`);
    setCopied(true);
  };

  return (
    <RightButtonGroupContainer>
      <Item>
        <ShareButton src={ShareIcon} alt='share' onClick={() => setOpenDropDown(!openDropDown)}/>
        <DropdownMenu dropDownOpen={openDropDown}>
          <div>
            <FacebookShareButton url={shareLink}>
              <img src={ShareFacebookIcon} alt='Facebook' />
            </FacebookShareButton>
          </div>
          <div>
            <TwitterShareButton url={shareLink}>
              <img src={ShareTwitterIcon} alt='Twitter' />
            </TwitterShareButton>
          </div>
          <ShareLink>
            {copied && <CopiedLink>{t('Link Copied')}</CopiedLink>}
            <img src={ShareCopyLinkIcon} alt='link' onClick={onCopyHandler} />
          </ShareLink>
        </DropdownMenu>
      </Item>
    </RightButtonGroupContainer>
  );
};

const Header = () => {
  return (
    <Container>
      <LeftButtonGroup />
      <ESPRIT src={EspritLogo} />
      <RightButtonGroup />
    </Container>
  );
};

export default Header;
