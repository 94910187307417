let fetchResponse: (url: string, opts?: RequestInit) => Promise<Response> = async (url, opts) => {
  let response = await fetch(url, {
    ...opts,
    headers: { ...(opts?.headers ?? {}) },
  });

  let contentType = response.headers.get('content-type') ?? 'application/json';
  if (response.ok) {
    return response;
  } else if (contentType === 'application/json') {
    let json = await response.json();
    let field = json?.errors?.[0]?.field;
    // eslint-disable-next-line no-throw-literal
    throw { message: 'Server returned HTTP ' + response.status + ': ' + JSON.stringify(json), field, json, url };
  } else {
    let text = await response.text();
    // eslint-disable-next-line no-throw-literal
    throw { message: 'Server returned HTTP ' + response.status + ': ' + text, text, url };
  }
};

let fetchJson: <T>(url: string, opts?: RequestInit) => Promise<T> = async (url, opts) => {
  let response = await fetchResponse(url, opts);
  let contentType = response.headers.get('content-type') ?? 'application/json';
  if (contentType === 'application/json') {
    return response.json();
  } else {
    // eslint-disable-next-line no-throw-literal
    throw { message: 'Server returned non-json ' + response.status };
  }
};

export { fetchResponse, fetchJson };
